<template>
  <modal
    :name="modalName"
    :width="600"
    :adaptive="true"
    :clickToClose="false"
    height="90%"
    class="modal--bank-account"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('bankForm.oneStep') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">{{ $t('bankForm.oneStep') }}</div>
        <bank-account-form
          :showNotNowBtn="showNotNowBtn"
          :showPhoneNumberField="showPhoneNumberField"
          @postDone="postDone"
          @notNow="close"
        />
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const BankAccountForm = () => import('@/components/bank-account/bank-account');

export default {
  name: 'input-bank-account-modal',
  props: {
    showNotNowBtn: {
      type: Boolean,
      default: false,
    },
    showPhoneNumberField: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalName: 'modal-input-bank-account',
    };
  },
  components: {
    BankAccountForm,
  },
  methods: {
    postDone() {
      this.$emit('postDone');
      this.$modal.hide(this.modalName);
    },
    close() {
      this.$emit('notNow');
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style></style>
